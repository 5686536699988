// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.btn-primary{
    color: $white;
    font-weight: bold;
}

.btn-primary:hover{
    color: $white;
}
